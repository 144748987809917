import React from 'react'
import { IoBusinessOutline, IoCalendar, IoLocationOutline, IoPencil, IoTimeOutline } from 'react-icons/io5';
import { mapEmploymentTypeToString } from '../../constants/employmentType';

const UserProfileWorkExperience = (props) => {
    const { experience, setWorkExperience, isPublic } = props;
    const endString = experience.endDate ?
        new Date(experience.endDate).toLocaleDateString("en-us", { year: "numeric", month: "long" }) :
        "Present";

    return (
        <div className='user-profile__workexperience'>
            <div className='flex flex-row justify-between mb-5'>
                <div className='flex flex-row'>
                    <h2>{experience.title}</h2>
                    {!isPublic &&
                        <>
                            <div
                                className='flex flex-row align-center ml-20 cursor-pointer'
                                onClick={() => setWorkExperience(prev => {
                                    return {
                                        ...prev,
                                        [experience.workExperienceId]: {
                                            ...experience,
                                            editing: true
                                        }
                                    }

                                })}
                            >
                                <div className='mr-5 gray font-size-15'>{"Edit Position"}</div>
                                <IoPencil className='gray' />
                            </div>
                        </>
                    }

                </div>
                <div className='flex flex-row align-center'>
                    <IoTimeOutline className='gray mr-5' />
                    <div className='data_field'>{mapEmploymentTypeToString(experience.employmentType)}</div>
                </div>
            </div>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row align-center'>
                    <div className='flex flex-row align-center'>
                        <IoBusinessOutline className='gray mr-5' />
                        <div className='data_field'>{experience.restaurant}</div>
                    </div>
                    <div className='flex flex-row align-center ml-10'>
                        <IoLocationOutline className='gray mr-5' />
                        <div className='data_field'>{experience.location}</div>
                    </div>
                </div>
                <div className='flex flex-row align-center'>
                    <IoCalendar className='gray mr-5' />
                    <div className='data_field'>
                        {new Date(experience.startDate).toLocaleDateString("en-us", { year: "numeric", month: "long" })}
                        {" - "}
                        {endString}
                    </div>
                </div>
            </div>
            <div className='flex gray mt-10 font-size-15 line-height-15'>
                {experience.description}
            </div>
        </div>
    )
}

export default UserProfileWorkExperience