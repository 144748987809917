import React, { useState } from 'react'
import { Button } from '../button.styles';
import Chip from '../chip/chip.component';
import { IoPencil } from 'react-icons/io5';
import { updateUserProfile } from '../../api/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PROFILE_KEY } from '../../constants/queryCache';
import Autocomplete from '../autocomplete/autocomplete.component';
import { Skills } from '../../constants/skills';
import RichEditor from '../rich-editor/rich-editor.component';

export const UserProfileSummary = (props) => {
    const { profile, isPublic } = props;
    const [editWorkSummary, setEditWorkSummary] = useState(false);
    const [skills, setSkills] = useState(profile.skills);
    const [summary, setSummary] = useState(profile.summary || "");

    const queryClient = useQueryClient();
    const updateProfileMutation = useMutation({
        mutationFn: updateUserProfile,
        onSuccess: (data) => {
            queryClient.setQueryData([PROFILE_KEY, data.userId], data);
            setEditWorkSummary(false);
        },
    })

    const updateUser = () => {
        const newProfile = {
            ...profile,
            summary,
            skills
        }
        updateProfileMutation.mutate(newProfile);
    }

    return (
        <div className='w-100'>
            <div className='flex flex-row justify-between ml-10'>
                <h1>{"Professional Summary"}</h1>
                {editWorkSummary && !isPublic && <Button color="aperol" onClick={updateUser}>{"Save Section"}</Button>}
                {!editWorkSummary && !isPublic && (
                    <div className='flex flex-row align-center ml-20 cursor-pointer' onClick={() => setEditWorkSummary(true)}>
                        <div className='mr-5 gray font-size-15'>{"Edit Section"}</div>
                        <IoPencil className='gray' />
                    </div>
                )}
            </div>
            <div className='mt-10 ml-10'>
                <RichEditor content={summary} onChange={text => setSummary(text)} editable={editWorkSummary} />
            </div>
            <div className='flex flex-row ml-10 mt-20'>
                {skills.map((skill) => (
                    <Chip
                        button={editWorkSummary}
                        key={skill}
                        label={skill} sx={{
                            "marginRight": "10px"
                        }}
                        onDelete={editWorkSummary ? () => setSkills(skills.filter(sk => sk !== skill)) : null}
                    />
                ))}
            </div>

            {editWorkSummary &&
                <div className='flex ml-10 mt-10'>
                    <Autocomplete
                        options={Skills}
                        fullWidth
                        onChange={(e, value) => {
                            if (value) {
                                setSkills(prev => [...new Set([...prev, value])])
                            }
                        }}
                        placeholder="Add Skill"
                    />
                </div>
            }
        </div>
    )
}

export default UserProfileSummary;
