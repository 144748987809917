import styled from "styled-components";
import { Link } from "react-scroll";
import { aperol, danger, indigo, secondaryButton } from "../constants/colors";

export const StyledButton = props => {
    const { children, ...rest } = props;
    return <button {...rest}>{children}</button>
}

export const Button = styled(StyledButton)`
    border-radius: 12px;
    background: ${({ color }) => nameToBackgroundColor(color)};
    white-space: nowrap;
    padding: ${({ big, icon }) => (big ? '14px 48px' : icon ? '5px' : '10px 20px')};
    color: ${({ color }) => nameToColor(color)};
    font-size: ${({ fontSize }) => fontSizeMap(fontSize)};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: "Montserrat";
    border: ${({ color }) => nameToBorder(color)};

    &:hover, &:focus {
        text-decoration: none;
        color: ${({ color }) => nameToColor(color)};
    }
    
    @media screen and (max-width: 768px) {

        font-family: "Poppins" !important;
        font-size: 12px !important;;
        font-style: normal;
        font-weight: 300  !important;;
        line-height: normal;

    }
`

export const ScrollButton = styled(Link)`
    border-radius: 12px;
    background: ${({ color }) => nameToBackgroundColor(color)};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '10px 20px')};
    color: ${({ color }) => color === "white" ? "#000" : "#fff"};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: "poppins";
    border: ${({ color }) => nameToBorder(color)};

    &:hover, &:focus {
        text-decoration: none;
        color: ${({ color }) => color === "white" ? "#000" : "#fff !important"};
    }
    
    @media screen and (max-width: 768px) {

        font-family: "Poppins" !important;
        font-size: 12px !important;;
        font-style: normal;
        font-weight: 300  !important;;
        line-height: normal;

    }
`

const nameToBackgroundColor = switchCase => {
    switch (switchCase) {
        case 'primary':
            return '#AE5020';
        case 'aperol':
            return aperol;
        case 'white':
        case 'danger':
        case 'gray':
            return "#fff";
        default:
            return '#010606';
    }
}

const nameToColor = switchCase => {
    switch (switchCase) {
        case 'white':
            return "#000";
        case 'gray':
            return indigo;
        case 'danger':
            return danger
        default:
            return '#fff';
    }
}

const nameToBorder = switchCase => {
    switch (switchCase) {
        case 'white':
            return "1px solid #d8d8d8";
        case 'gray':
            return "1px solid #d8d8d8";
        case 'danger':
            return `1px solid ${danger}`;
        default:
            return 'none';
    }
}

const fontSizeMap = switchCase => {
    switch (switchCase) {
        case 'large':
            return '20px';
        case 'medium':
            return '16px';
        case 'small':
            return '14px';
        default:
            return '16px';
    }
}