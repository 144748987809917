import React from 'react'
import { IoTimeOutline } from 'react-icons/io5';
import { mapEmploymentTypeToString } from '../../constants/employmentType';
import RichEditor from '../rich-editor/rich-editor.component';
import { Button } from '../button.styles';

const BusinessProfilePosition = (props) => {
    const { jobPosting, divider, isPublic } = props;
    return (
        <>
            <div className='flex flex-column charcoal mb-20'>
                <div className='flex flex-row align-center justify-between mb-10'>
                    <h2>{jobPosting.title}</h2>
                    <div className='flex flex-row align-center mb-10'>
                        <IoTimeOutline className='mr-5' />
                        {mapEmploymentTypeToString(jobPosting.employmentType)}
                    </div>
                </div>
                <div className='gray'>
                    <RichEditor content={jobPosting.description} editable={false} />
                </div>
            </div>
            {isPublic &&
                <div className={"flex"}>
                    <Button color='aperol' onClick={() => {
                        // TODO: Apply to job
                    }}>
                        {"Apply Now"}
                    </Button>
                </div>
            }
            {divider && <div className='divider' />}
        </>
    )
}

export default BusinessProfilePosition