import React, { useEffect, useState } from 'react';
import betterShiftLogo from "../../images/betterShiftLogo.svg"
import mobileBetterShiftLogo from "../../images/mobileLogoWhite.svg"
import { useLocation, useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import {
  Nav,
  NavBarContainer,
  MobileIcon,
  NavItem,
  NavLinks,
  NavMenu,
  NavBtn,
  NavBtnLinkSignIn
} from './nav-bar.styles';

import { ModalSignIn } from '../signin/signin-modal.component';
import { ModalSignUp } from '../signup/signup-modal.component';
import SearchBar from "../search-bar/search-bar.component";
import NavProfileDropdown from '../nav-profile-dropdown/nav-profile-dropdown.component';

import { useAuth } from "../../contexts/auth.context";
import { useModal } from '../../contexts/modal.context';

import { useMediaQuery } from 'react-responsive'


import hamburgerMenu from "../../images/hamburgerMenuWhite.svg"

import "../../styles/navbar.scss";

const NavBar = ({ toggle }) => {
  const { showModal, setShowModal, showModalSignUp, setShowModalSignUp } = useModal()
  const { currentUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [forceSearchBar, setForceSearchBar] = useState(false);
  const [hideSearchBar, setHideSearchBar] = useState(location.pathname === '/');
  const [navBg, setNavBg] = useState(location.pathname === '/' ? 'rgba(0,0,0,0)' : `rgb(35,36,34)`);

  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })


  const openModal = () => {
    setShowModal(prev => !prev);
  };

  const openModalSignUp = () => {
    setShowModalSignUp(prev => !prev);
  };

  useEffect(() => {
    const scrollpos = localStorage.getItem('scrollpos');
    if (scrollpos) {
      window.scrollTo(0, scrollpos);
      localStorage.removeItem('scrollpos');
    }
    const beforeUnload = function (e) {
      localStorage.setItem('scrollpos', window.scrollY);
    }

    window.onbeforeunload = beforeUnload
  }, []);

  useEffect(() => {
    const changeNav = () => {
      const threshold = 100;
      const thresholdSearch = 400;
      if (window.scrollY < threshold) {
        setNavBg(`rgba(35,36,34,${window.scrollY / threshold})`)
      } else if (window.scrollY < thresholdSearch) {
        setForceSearchBar(false)
      } else {
        if (!forceSearchBar) {
          setForceSearchBar(true)
        }
        setNavBg(`rgb(35,36,34)`);
      }
    }

    if (hideSearchBar) {
      changeNav();
      window.addEventListener('scroll', changeNav);
    } else {

      setNavBg(`rgb(53,62,66)`);
    }

    return () => {
      if (hideSearchBar) {
        window.removeEventListener('scroll', changeNav);
      }
    }
  }, [hideSearchBar, forceSearchBar])

  useEffect(() => {
    setHideSearchBar(location.pathname === '/')
  }, [location.pathname])

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <ModalSignIn showModal={showModal} setShowModal={setShowModal} setShowModalSignUp={setShowModalSignUp} />
        <ModalSignUp showModalSignUp={showModalSignUp} setShowModalSignUp={setShowModalSignUp} setShowModal={setShowModal} />
        <Nav navBg={navBg}>
          <NavBarContainer>
            <img src={isDesktop ? betterShiftLogo : mobileBetterShiftLogo} className="navbar__logo" alt="bettershift" onClick={(e) => {
              navigate('/')
            }} />
            <MobileIcon onClick={toggle}>
              <img src={hamburgerMenu} className="navbar__hamburger" alt="menu" />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  duration={500}
                  exact='true'
                  offset={-80}
                  onClick={() => {
                    navigate("/")
                  }}
                  to={"/"}
                >Home</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  duration={500}
                  exact='true'
                  offset={-80}
                  onClick={() => {
                    navigate("/about")
                  }}
                  to={"/about"}
                >About Us</NavLinks>
              </NavItem>
              {!currentUser && <NavItem>
                <NavLinks
                  duration={500}
                  exact='true'
                  offset={-80}
                  onClick={openModalSignUp}
                  to={"#"}
                >
                  Get Started
                </NavLinks>
              </NavItem>}
              <NavItem>
                <NavLinks
                  duration={500}
                  exact='true'
                  offset={-80}
                  onClick={() => {
                    navigate("/contact")
                  }}
                  to={"/contact"}
                >
                  Contact Us
                </NavLinks>
              </NavItem>
              <NavItem className='nav-divider' />
              <NavItem>
                <NavLinks
                  className={location.pathname === '/jobs' ? 'active' : ''}
                  to={"/jobs"}
                >
                  Job Board
                </NavLinks>
              </NavItem>
            </NavMenu>
            <div className="navbar__options">
              {isDesktop && (!hideSearchBar || forceSearchBar) && <NavItem>
                <SearchBar isHeader={true} setShowingMembers={() => { }} />
              </NavItem>}
              {isDesktop && !currentUser && <NavBtn>
                <NavBtnLinkSignIn onClick={openModal}>Sign In</NavBtnLinkSignIn>
              </NavBtn>}
              {currentUser && <NavProfileDropdown currentUser={currentUser} />}
            </div>

          </NavBarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default NavBar;