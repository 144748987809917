import React from 'react'
import { Button } from '../button.styles';
import TextArea from '../textarea/textarea.component';
import Input from '../input/input.component';
import { EmploymentTypes } from '../../constants/employmentType';
import Select from '../select/select.component';
import MenuItem from '../menuitem/menuitem.component';
import Checkbox from '../checkbox/checkbox.component';
import { Months, getYears } from '../../constants/date';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateUserProfile } from '../../api/user';
import { PROFILE_KEY } from '../../constants/queryCache';

export const UserProfileWorkExperienceForm = (props) => {
    const { profile, workExperience, experience, setWorkExperience } = props;
    const startDate = new Date(experience.startDate);
    const endDate = new Date(experience.endDate);

    const queryClient = useQueryClient();
    const updateProfileMutation = useMutation({
        mutationFn: updateUserProfile,
        onSuccess: (data) => {
            queryClient.setQueryData([PROFILE_KEY, data.userId], data);
            setWorkExperience({
                ...data.workExperience
            })
        },
    })

    const updateProfile = (values) => {
        const mappedValues = {
            workExperienceId: experience.workExperienceId,
            title: values.title,
            employmentType: values.employmentType,
            restaurant: values.restaurant,
            location: values.location,
            startDate: new Date(values.startYear, values.startMonth),
            endDate: values.currentJob ? null : new Date(values.endYear, values.endMonth),
            description: values.description
        }

        const profileUpdate = {
            ...profile,
            workExperience: {
                ...workExperience,
                [experience.workExperienceId]: {
                    ...mappedValues
                }
            }
        }

        updateProfileMutation.mutate(profileUpdate);
    }

    const deleteWorkExperience = (e) => {
        e.preventDefault();

        const profileUpdate = {
            ...profile,
            workExperience: Object.fromEntries(Object.entries(workExperience).filter(([key]) => key !== experience.workExperienceId))
        }

        updateProfileMutation.mutate(profileUpdate);
    }


    const { register, handleSubmit, formState: { errors }, control, watch } = useForm({
        defaultValues: {
            title: experience.title,
            employmentType: experience.employmentType || EmploymentTypes[0].value,
            restaurant: experience.restaurant,
            location: experience.location,
            startMonth: startDate.getMonth() || 1,
            startYear: startDate.getFullYear() || new Date().getFullYear(),
            currentJob: !endDate,
            endMonth: endDate.getMonth() || 1,
            endYear: endDate.getFullYear() || new Date().getFullYear(),
            description: experience.description
        }
    });
    const watchStartYear = watch("startYear", startDate.getFullYear() || new Date().getFullYear());
    const watchStartMonth = watch("startMonth", startDate.getMonth() || 1);
    const watchEndYear = watch("endYear", endDate.getFullYear() || new Date().getFullYear());
    const watchCurrentJob = watch("currentJob", false);

    return (
        <form className='w-100 mt-20 mb-20' onSubmit={handleSubmit(updateProfile)}>
            <div className='ml-10'>
                <h2 className='mt-20 mb-10'>{"Position Title"}</h2>
                <Input fullWidth error={errors.title} inputProps={{ ...register("title", { required: "Title is required", maxLength: { value: 200, message: "Max length is 200" } }) }} />
                <h2 className='mt-20 mb-10'>{"Employment Type"}</h2>
                <Controller
                    name="employmentType"
                    render={({ field: { onChange, value } }) => (
                        <Select fullWidth onChange={onChange} value={value}>
                            {EmploymentTypes.map(type => (
                                <MenuItem value={type.value} key={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    control={control}
                />
                <h2 className='mt-20 mb-10'>{"Restaurant Name"}</h2>
                <Input fullWidth error={errors.restaurant} inputProps={{ ...register("restaurant", { required: "Restaurant is required", maxLength: { value: 200, message: "Max length is 200" } }) }} />
                <h2 className='mt-20 mb-10'>{"Location"}</h2>
                <Input fullWidth error={errors.location} inputProps={{ ...register("location", { required: "Location is required", maxLength: { value: 200, message: "Max length is 200" } }) }} />
                <h2 className='mt-20 mb-10'>{"Start Date"}</h2>
                <div className='flex flex-row align-center'>
                    <Controller
                        name="startMonth"
                        render={({ field: { onChange, value } }) => (
                            <Select fullWidth onChange={onChange} value={value}>
                                {Months.map((type, index) => (
                                    <MenuItem value={index} key={type} disabled={new Date(watchStartYear, Months.indexOf(type)) > new Date()}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        control={control}
                    />
                    <Controller
                        name="startYear"
                        render={({ field: { onChange, value } }) => (
                            <Select fullWidth onChange={onChange} value={value}>
                                {getYears(1900).map(type => (
                                    <MenuItem value={type} key={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        control={control}
                    />
                </div>
                <Controller
                    name="currentJob"
                    render={({ field: { onChange, value } }) => (
                        <Checkbox
                            checked={value}
                            onChange={onChange}
                            label={"I currently work here"}
                        />
                    )}
                    control={control}
                />

                {!watchCurrentJob && (
                    <>
                        <h2 className='mt-20 mb-10'>{"End Date"}</h2>
                        <div className='flex flex-row align-center'>
                            <Controller
                                name="endMonth"
                                render={({ field: { onChange, value } }) => (
                                    <Select fullWidth onChange={onChange} value={value}>
                                        {Months.map((type, index) => (
                                            <MenuItem value={index} key={type} disabled={new Date(watchEndYear, Months.indexOf(type)) > new Date()}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                control={control}
                                rules={{
                                    validate: month => {
                                        if (new Date(watchStartYear, watchStartMonth) <= new Date(watchEndYear, month)) {
                                            return true;
                                        }
                                        return "End date must be after start date";
                                    }
                                }}
                            />
                            <Controller
                                name="endYear"
                                render={({ field: { onChange, value } }) => (
                                    <Select fullWidth onChange={onChange} value={value}>
                                        {getYears(1900).map(type => (
                                            <MenuItem value={type} key={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                control={control}
                            />
                        </div>
                    </>
                )}
                {errors.endMonth && <div className='text-error'>{errors.endMonth.message}</div>}
                <h2 className='mt-20 mb-10'>{"Description"}</h2>
                <TextArea
                    fullWidth
                    error={errors.description}
                    inputProps={{ ...register("description", { maxLength: { value: 2000, message: "Max length is 2000" } }) }}
                />
            </div>
            <div className='user-profile__buttons'>
                <Button color="danger" className='ml-10 mt-20' onClick={deleteWorkExperience}>{"Delete Position"}</Button>
                <Button color="aperol" type={"submit"} className='ml-10 mt-20'>{"Save Position"}</Button>
            </div>
        </form>
    )
}

export default UserProfileWorkExperienceForm;
