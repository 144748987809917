import React from 'react'
import { Controller } from 'react-hook-form'
import Select from '../select/select.component'
import Input from '../input/input.component'
import { SalaryTypes } from '../../constants/salaryType'
import MenuItem from '../menuitem/menuitem.component'

const SalaryInput = (props) => {
    const { register, control, amountField, timeUnitField } = props
    return (
        <div className='flex flex-row aligne-center'>
            <Input fullWidth type={"number"} startAdornment={<div className='ml-5'>$</div>} inputProps={{
                ...register(amountField, {
                    required: "Salary Amount is required", maxLength: { value: 10, message: "Max length of 10 characters" },
                }),
            }} />
            <Controller
                name={timeUnitField}
                render={({ field: { onChange, value } }) => (
                    <Select fullWidth onChange={onChange} value={value}>
                        {SalaryTypes.map(type => (
                            <MenuItem value={type.value} key={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                control={control}
            />
        </div>
    )
}

export default SalaryInput