import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../button.styles';
import {
    InfoContainer,
    InfoWrapper,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap
} from './info.styles';

import { useMediaQuery } from 'react-responsive'
import { useAuth } from "../../contexts/auth.context";
import { useModal } from '../../contexts/modal.context';

import "./info.scss";
import { getCurrentBusinessProfile } from '../../utils/storage';
import { homeObjUser, homeObjBiz } from './info.data';

const InfoSection = () => {
    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const { setShowModalSignUp } = useModal()

    const isDesktop = useMediaQuery({
        query: '(min-width: 768px)'
    })

    const currentBizProfile = getCurrentBusinessProfile();

    const { topLine, headline, description, lightText, darkText, image } = currentBizProfile === "user" ? homeObjUser : homeObjBiz;

    return (
        <InfoContainer>
            <InfoWrapper>
                <div className="info-section__left">
                    <div data-aos="fade-in" data-aos-duration="1000" style={{ width: "100%" }}>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BtnWrap>
                                <Button
                                    color={"aperol"}
                                    onClick={() => {
                                        if (currentUser) {
                                            navigate(currentBizProfile === "user" ? "/jobs" : "/positions")
                                        } else {
                                            setShowModalSignUp(true)
                                        }
                                    }}
                                >
                                    {"Get Started"}
                                </Button>
                            </BtnWrap>
                        </TextWrapper>
                    </div>
                </div>
                <div className="info-section__right">
                    <div className="info-section__image">
                        <img src={image} alt="man with apron" />
                        {!isDesktop && <div className="info__bg-overlay">
                            <div className="info__testimonial">
                                <p className="info__testimonial-text">“Where transparency meets opportunity. Together, we can build a community that promotes healthy work environments within the restaurant industry.”</p>
                                <label className="info__testimonial-label">Sam Gustafsson</label>
                                <span className="info__testimonial-subtitle">FOUNDER/CEO</span>
                            </div>
                        </div>}

                    </div>
                </div>
            </InfoWrapper>
        </InfoContainer>
    )
}

export default InfoSection
