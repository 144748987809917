import React from 'react'
import MatChip from '@mui/material/Chip';
import { aperol, gray } from '../../constants/colors';

const Chip = (props) => {
    const { button, sx, label, variant, ...other } = props;

    let style = {
        "borderRadius": "8px",
        "color": gray,
    };

    if (props.button) {
        style = {
            ...style,
            "color": aperol,
            "borderColor": aperol,
            "cursor": "pointer"
        }
    }
    return (
        <MatChip
            label={props.label}
            variant={props.variant || "outlined"}
            {...other}
            sx={{
                ...style,
                ...props.sx
            }} />
    )
}

export default Chip