import React from 'react'
import Input from '../input/input.component'
import { Button } from '../button.styles'
import { useModal } from '../../contexts/modal.context'
import CreatePositionForm from '../create-position-form/create-position-form.component'

const PositionsHeader = (props) => {
    const { jobPostings, search, setSearch } = props;
    const { setModal } = useModal();

    return (
        <div>
            <div className='flex flex-row align-center justify-between p-2 pb-0'>
                <h2 className='mr-20'>{"Position Center"}</h2>
                <div className='w-40'>
                    <Input
                        type="text"
                        short
                        searchIcon
                        searchButton
                        placeholder='Search by Position or Name'
                        value={search}
                        onChange={eve => setSearch(eve.target.value)}
                        fullWidth
                    />
                </div>
                <Button color="white">
                    {"Generate QR Code"}
                </Button>
                <Button color="aperol" onClick={() => setModal({
                    show: true,
                    modal: "create-position",
                    children: <CreatePositionForm />,
                    closeIcon: false
                })}>
                    {"+ Create a New Position"}
                </Button>
            </div>
            <div className='divider' />
            <div className='flex flex-row align-center justify-around pl-10 pr-10'>
                <StatBox title='Job(s) Filled' value={jobPostings.filter(pos => pos.status === "CLOSED").length} />
                <StatBox title='Position(s) Open for Hire' value={jobPostings.filter(pos => pos.status === "OPEN").length} />
                <StatBox title='Set Interviews' value={jobPostings.filter(pos => pos.status === "INTERVIWING").length} />
                {/* TODO: Add stat box */}
                <StatBox title='Unreviewed Applicants' value='2' />
            </div>
        </div>

    )
}

const StatBox = props => {
    return (
        <div className='flex flex-col position-stat-box'>
            <h3 className='aperol'>{props.value}</h3>
            <div className='gray font-size-14'>{props.title}</div>
        </div>
    )
}

export default PositionsHeader