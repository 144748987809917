import React from 'react'
import UserProfileWorkExperienceForm from './user-profile-work-experience-form.component';
import UserProfileWorkExperience from './user-profile-work-experience.component';
import Chip from '../chip/chip.component';
import { useState } from 'react';

const UserProfileWorkExperienceList = (props) => {
    const { profile, isPublic } = props;
    const [workExperience, setWorkExperience] = useState(profile.workExperience || {});
    return (
        <>
            <div className='flex flex-row justify-between ml-10 mb-10'>
                <h1>{"Work Experience"}</h1>
                {!isPublic && <Chip label="+ Add Another Position" button onClick={() => setWorkExperience(prev => {
                    const newExperience = {
                        workExperienceId: crypto.randomUUID(),
                        editing: true
                    }
                    return { ...prev, [newExperience.workExperienceId]: newExperience }
                })} />}
            </div>
            <div>
                {[...Object.values(workExperience)]
                    .sort((x, y) => new Date(y.endDate) - new Date(x.startDate))
                    .map((experience, index) => experience.editing ? (
                        <UserProfileWorkExperienceForm key={index} profile={profile} workExperience={workExperience} experience={experience} setWorkExperience={setWorkExperience} />
                    ) : <UserProfileWorkExperience key={index} experience={experience} setWorkExperience={setWorkExperience} isPublic={isPublic} />
                    )}
            </div>
        </>
    )
}

export default UserProfileWorkExperienceList