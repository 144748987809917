import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth.context";
import { getCurrentBusinessProfile } from "../../utils/storage";

const PrivateRoute = ({ children, requiresBusinessProfile }) => {
    const { currentUser, fetchedUser } = useAuth();

    if (!fetchedUser)
        return <div></div>

    if ((fetchedUser && !currentUser) || (requiresBusinessProfile && getCurrentBusinessProfile() === 'user')) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default PrivateRoute;