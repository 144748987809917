import React from 'react'
import cx from 'classnames';
import { IoBookmark, IoBookmarkOutline, IoFilter } from 'react-icons/io5';
import { Button } from '../button.styles';
import JobSearchBar from '../search-bar/job-search-bar.component';

const JobSearchHeader = (props) => {
    const { keyword, setKeyword, location, setLocation, onSubmit, totalItems, filters, setFilters } = props;

    return (
        <div className='flex flex-column'>
            <h1 className='mb-10' style={{ fontSize: "2rem" }}>Top jobs available</h1>
            <div className="flex flex-row justify-between align-center">
                <div>{`${Math.max(totalItems, 10)}+ Jobs Found`}</div>
                <div className='flex flex-row align-center mr-5 cursor-pointer' onClick={() => setFilters(old => ({ ...old, favorites: !old.favorites }))}>
                    {filters.favorites ? <IoBookmark /> : <IoBookmarkOutline />}
                    <div className={cx({ gray: !filters.favorites, charcoal: filters.favorites, 'ml-5': true })}>{"View My Saved Jobs"}</div>
                </div>
            </div>
            <JobSearchBar
                keyword={keyword}
                setKeyword={setKeyword}
                location={location}
                setLocation={setLocation}
                onSubmit={onSubmit}
                endAdornment={
                    <div className='flex flex-row align-center justify-end'>
                        <Button fontSize="small" color="gray" className="mr-10">
                            <IoFilter className='mr-5' />
                            {"Filters"}
                        </Button>
                        <Button fontSize="small" color="aperol" type={"submit"}>{"Search"}</Button>
                    </div>
                }
            />
        </div>
    )
}

export default JobSearchHeader