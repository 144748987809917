import webClient from "./webClient";


export const getBusinessProfile = async (businessId, slug = false) => {
    return webClient.get(`/businesses/${businessId}`, { params: { slug } });
}

export const createBusinessProfile = async (body) => {
    return webClient.post(`/businesses`, body);
}

export const updateBusinessProfile = async (profile) => {
    return webClient.put(`/businesses/${profile.businessId}`, profile);
}

export const getPresignedUrl = async ({ key, businessId, filename, filetype }) => {
    const resp = await webClient.post(
        `/businesses/${businessId}/presignedurl`,
        {
            key,
            filename,
            filetype
        },
    );
    return resp;
}