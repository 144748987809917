import React from 'react'

const BusinessProfileReferences = () => {
    return (
        <div>
            <h1>{"No Bettershift References"}</h1>
            <div className='gray'>{"Ask an employee to write one!"}</div>
        </div>
    )
}

export default BusinessProfileReferences