import { useState } from "react"
import { resetPassword } from "../../firebase";
import "./reset-password.styles.scss";

import checkGrey from "../../images/check-grey.svg";
import { useNavigate } from "react-router-dom";


function ResetPassword(props) {
    const { oobCode } = props;
    const [errors, setErrors] = useState([])
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [completedState, setCompletedState] = useState(false)
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const resetPasswordHandler = async (e) => {
        setErrors([])
        e.preventDefault();
        setLoading(true);

        resetPassword({ password, oobCode })
            .then(resp => setCompletedState(true))
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
        return false;
    }
    if (completedState) {
        return <div className="change-password change-password__completed-state">
            <h2 className="change-password__heading-title">Successfully Changed Password</h2>

            <img src={checkGrey} alt="check" />

            <button className="change-password__submit" onClick={() => {
                navigate("/user")
            }}>Go To Profile</button>
        </div>
    }
    return <form className="change-password">
        <div className="change-password__heading">
            <h2 className="change-password__heading-title">Change password</h2>
        </div>
        {errors && errors.length > 0 && errors.map((error) => <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>)}
        <div className="change-password__form">

            <div className="change-password__input-row">
                <label className="change-password__input-label">New Password</label>
                <input className="change-password__input-text" type="password" value={password} placeholder="*********" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} required />
            </div>

            <div className="change-password__input-row">
                <label className="change-password__input-label">Confirm Password</label>
                <input className="change-password__input-text" type="password" value={confirmPassword} placeholder="*********" autoComplete="new-password" onChange={(e) => setConfirmPassword(e.target.value)} required />
            </div>
        </div>
        <div className="change-password__footer">
            <button className="change-password__submit" disabled={loading} onClick={resetPasswordHandler}>Reset Password</button>
        </div>
    </form>
}

export default ResetPassword