import Icon1 from '../../images/svg-1.svg'
import jobsearchLanding2 from "../../images/jobsearch_landing_2.png"
import manWithApron from "../../images/manWithApron.png";

export const homeObjUser = {
    lightText: false,
    topLine: '',
    headline: 'Find the perfect job that suits you',
    description: "Our platform connects you with a wide range of job opportunities in the service industry. Whether you're a chef, server, bartender, or manager, BetterShift helps you find the perfect job that matches your skills and preferences. Sign up today and take the next step in your career!",
    darkText: true,
    image: jobsearchLanding2
};

export const homeObjBiz = {
    lightText: false,
    topLine: '',
    headline: 'Find the perfect employee that suits you',
    description: "Our platform connects you with a wide range of workers within the service industry. Whether you're looking for a chef, server, bartender, or manager, BetterShift helps you find the perfect employee that matches your needs and preferences. Sign up today and take the next step in your hiring process!",
    darkText: true,
    image: manWithApron
};

export const getStartedObj = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Never feel disposable again',
    headline: 'You Matter',
    description: "Our mission is to help restaurant workings know ahead of time how they will be treated and how much money they'll be able to make without any fake promises from the managers and owners.",
    description2: "Every person derserves to work in an environment where they feel safe. Figure out which is a great restuarant to work for and which restuarant's to avoid by knowing people's experience ahead of time!",
    buttonLabel: 'Sign Up!',
    imgStart: false,
    img: Icon1,
    alt: 'Rating',
    dark: true,
    primary: true,
    darkText: false
};