import webClient from "./webClient";

export const getJobPostings = async (params) => {
    return webClient.get("/jobs", { params });
}

export const createJobPosting = async (body) => {
    return webClient.post("/jobs", body);
}

export const editJobPosting = async (body) => {
    return webClient.put(`/jobs/${body.jobPostingId}`, body);
}

export const deleteJobPosting = async (id) => {
    return webClient.delete(`/jobs/${id}`);
}