import React, { useEffect } from 'react';
import { useAuth } from "../contexts/auth.context";
import { getUserProfile, createUserProfile } from '../api/user';
import ScrollToTop from '../components/scrollToTop';

import { UserProfileHeader } from '../components/user-profile-header/user-profile-header.component';
import UserProfileSummary from '../components/user-profile-summary/user-profile-summary.component';
import UserProfileReferences from '../components/user-profile-references/user-profile-references.component';
import UserProfileExperienceList from '../components/user-profile-experience/user-profile-experience-list.component';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '../components/circular-progress/circular-progress';
import UserProfileDegreeList from '../components/user-profile-degree/user-profile-degree-list.component';

import "../styles/user-profile.scss";
import { useModal } from '../contexts/modal.context';
import { PROFILE_KEY } from '../constants/queryCache';
import { useParams } from 'react-router-dom';

const UserProfile = () => {
  const { userSlug } = useParams();
  const { currentUser } = useAuth();
  const { modal } = useModal();
  const userId = userSlug ? userSlug : currentUser.uid;
  const isPublic = userSlug ? true : false;

  const { data: profile, isLoading, isError, refetch } = useQuery({
    queryKey: [PROFILE_KEY, userId], queryFn: async () => {
      try {
        return await getUserProfile(userSlug);
      } catch (err) {
        if (err.response.status === 404 && !isPublic) {
          return await createUserProfile({
            name: currentUser.displayName,
            email: currentUser.email,
          });
        }
        throw err;
      }
    }
  });

  useEffect(() => { refetch() }, [modal.show, refetch]);

  if (isLoading) return <div className='loading-container min-height-60'><CircularProgress /></div>;

  if (isError) return <div className='ml-10'>Error fetching profile</div>;

  return (
    <div
      className="user-profile-wrapper w-100"
      style={{
        backgroundColor: "#F6F6F6",
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: "column",
        minHeight: '60vh',
      }}
    >
      <ScrollToTop />
      <UserProfileHeader profile={profile} isPublic={isPublic} />
      <div className='divider' />
      <div className="flex justify-between flex-wrap container">
        <div className="flex-2">
          <UserProfileSummary
            profile={profile}
            isPublic={isPublic}
          />
          <div className="vertical-spacer" />
          <UserProfileExperienceList profile={profile} isPublic={isPublic} />
          <div className="vertical-spacer" />
          <UserProfileDegreeList profile={profile} isPublic={isPublic} />
          <div className="vertical-spacer" />
        </div>
        <div className="flex-fill">
          <UserProfileReferences profile={profile} isPublic={isPublic} />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;