import React, { useContext } from "react"
import { useFirebase } from "../firebase";

const AuthContext = React.createContext()

export function AuthProvider({ children }) {
  const { currentUser, fetched } = useFirebase();

  const value = {
    currentUser,
    fetchedUser: fetched
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}