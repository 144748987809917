import React from 'react'
import { IoCheckmark, IoEllipsisHorizontal, IoEye, IoTrashBin, IoWarningOutline } from 'react-icons/io5'
import { Button } from '../button.styles'
import { charcoal, gray, goji, honey } from '../../constants/colors'
import Table from '../table/table.component'
import { matchSearch } from '../../utils/strings'
import { useModal } from '../../contexts/modal.context'
import CreatePositionForm from '../create-position-form/create-position-form.component'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { JOB_POSITIONS_KEY, removeFromPaginatedList } from '../../constants/queryCache'
import { deleteJobPosting } from '../../api/jobs'

const PositionButtons = props => {
    const { row } = props;
    const { setModal } = useModal();

    const queryClient = useQueryClient();
    const deleteJobPostingMutation = useMutation({
        mutationFn: deleteJobPosting,
        onSuccess: (data) => {
            queryClient.setQueryData([JOB_POSITIONS_KEY], old => removeFromPaginatedList(old, data, d => d.jobPostingId));
        }
    });

    const deletePosition = () => {
        deleteJobPostingMutation.mutate(row.jobPostingId);
    }

    return (
        <div className='flex flex-row align-center justify-center'>
            <Button
                color="charcoal"
                className='mr-20'
                fontSize={"small"}
                onClick={() => setModal({
                    show: true,
                    modal: "create-position",
                    children: <CreatePositionForm jobPosting={row} />,
                    closeIcon: false
                })}
            >
                {"Edit Position"}
            </Button>
            <IoTrashBin color={charcoal} size={20} className='cursor-pointer' onClick={() => deletePosition()} />
        </div>
    )
}


const columns = [
    { field: 'title', headerName: 'Position', variant: 'head', render: (row) => <div className='aperol cursor-pointer'>{row.title}</div> },
    {
        field: 'status',
        headerName: 'Status',
        render: (row) => {
            return (
                <div className='flex flex-row align-center'>
                    {renderStatusIcon(row.status)}
                    <div className='ml-10' style={{ color: statusToColor(row.status) }}>{statusToString(row.status)}</div>
                </div>
            )
        }
    },
    {
        field: 'createdAt',
        headerName: 'Date Posted',
        render: (row) => <div className='gray'>{new Date(row.createdAt).toLocaleDateString()}</div>
    },
    {
        field: 'totalApplicants',
        headerName: 'Total Appl.',
        align: 'center',
        render: (row) =>
            <div className='flex flex-row align-center justify-center'>
                <div className='charcoal mr-10' style={{ fontWeight: 500 }}>{row.totalApplicants || 0}</div>
                <IoEye color={charcoal} size={20} className='cursor-pointer' />
            </div>
    },
    {
        field: 'buttons',
        headerName: '',
        render: (row) => <PositionButtons row={row} />
    },
];

const renderStatusIcon = (status) => {
    switch (status) {
        case "OPEN":
            return <IoWarningOutline color={goji} size={20} />
        case "INTERVIEWING":
            return <IoEllipsisHorizontal color={honey} size={20} />
        case "CLOSED":
            return <IoCheckmark color={gray} size={20} />
        default:
            return <div className='status-icon' />
    }
}

const statusToColor = (status) => {
    switch (status) {
        case "OPEN":
            return goji;
        case "INTERVIEWING":
            return honey;
        case "CLOSED":
            return gray;
        default:
            return charcoal;
    }
}

const statusToString = (status) => {
    switch (status) {
        case "OPEN":
            return "No Invites Sent";
        case "INTERVIEWING":
            return "Interviewing";
        case "CLOSED":
            return "Filled";
        default:
            return "N/A";
    }
}

const PositionsTable = (props) => {
    const { jobPostings, filter } = props;
    const mappedPositions = jobPostings
        .filter(pos => filter && pos.title ? matchSearch(pos.title, filter) : true)
        .map(position => {
            return {
                ...position,
                id: position.jobPostingId
            }
        });

    return (
        <div className='p-2'>
            <Table
                rows={mappedPositions || []}
                columns={columns}
                renderEmpty={() =>
                    <tr>
                        <td>
                            <div className='flex align-center p-2'>
                                {"No positions open. Create a new position above!"}
                            </div>
                        </td>
                    </tr>
                } />

        </div>
    )
}

export default PositionsTable

