import React from 'react'
import { InputAdornment, TextField } from '@mui/material';
import { gray } from '../../constants/colors';
import { IoSearch } from 'react-icons/io5';
import { Button } from '../button.styles';

const Input = (props) => {
    const { error, short, searchIcon, searchButton, ...other } = props;
    return (
        <>
            <TextField
                sx={{
                    "input": {
                        fontFamily: "Montserrat",
                        color: gray,
                        fontSize: "15px",
                        fontWeight: "400"
                    },
                    "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                    },
                    "& .MuiOutlinedInput-input": {
                        padding: short ? "12.5px 0px" : "10px 10px",
                    },
                    '& ::before': {
                        borderBottom: 'none',
                    },
                    '& :hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: 'none',
                    },
                    '& .Mui-focused:before': {
                        borderBottom: 'none',
                    },
                    '& .Mui-focused:after': {
                        borderBottom: 'none',
                    },
                }}
                InputProps={{
                    startAdornment: searchIcon ?
                        (<InputAdornment position="start">
                            <IoSearch />
                        </InputAdornment>) : props.startAdornment ?
                            (<InputAdornment position="start">
                                {props.startAdornment}
                            </InputAdornment>) : null,
                    endAdornment: searchButton && (
                        <InputAdornment position="end">
                            <Button color="aperol">
                                {"Search"}
                            </Button>
                        </InputAdornment>
                    )
                }}
                {...other}
            />
            {error && <div className='text-error'>{error.message}</div>}
        </>
    )
}

export default Input;