import * as React from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';
import { gray } from '../../constants/colors';

const Input = React.forwardRef(function CustomInput(props, ref) {
    const { icon, startAdornment, endAdornment, fullWidth, ...other } = props;
    return (
        <BaseInput
            slots={{ input: InputElement }}
            startAdornment={startAdornment && <InputAdornment>{startAdornment}</InputAdornment>}
            endAdornment={endAdornment && <InputAdornment>{endAdornment}</InputAdornment>}
            {...other}
            ref={ref}
            style={{
                display: "flex",
                height: "100%",
                width: fullWidth ? "100%" : "auto",
            }}
        />
    );
});

export default function BorderlessInput(props) {
    return <Input {...props} />;
}

const InputElement = styled('input')(
    ({ theme }) => `
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  padding: 0px 4px;
  color: ${gray};
  border: none;
  box-shadow: none;

  &:hover {
    border: none;
}

  &:focus {
    border: none;
  }

  &:focus-visible {
    outline-color: #FFF;
  }
`,
);

const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;