import React, { useState, useEffect } from 'react';
import HeroSection from '../components/hero-section/hero.component';
import InfoSection from '../components/info-section/info.component';
import { getStartedObj } from '../components/info-section/info.data';
import Services from '../components/info-section/services.component';
import Sidebar from '../components/side-bar/side-bar.component';
import ScrollToTop from '../components/scrollToTop';

import { useSearchParams, useNavigate } from 'react-router-dom';

import { useModal } from '../contexts/modal.context';
const Home = () => {
  const { setShowModal } = useModal()
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (searchParams.get('signin') === "true") {
      setShowModal(true)
    } else if (searchParams.get('redirect_last_page') === "true") {
      const lastPage = localStorage.getItem("BS_redirect_last_page");
      if (lastPage) {
        navigate(lastPage)
        localStorage.removeItem("BS_redirect_last_page");
      }
    }
  }, [navigate, searchParams, setShowModal])

  return (
    <>
      <ScrollToTop />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroSection />
      <InfoSection />
      <Services {...getStartedObj} />
    </>
  );
};

export default Home;