import React, { useState } from 'react'
import { Button } from '../button.styles';
import Chip from '../chip/chip.component';
import { IoPencil } from 'react-icons/io5';
import { updateBusinessProfile } from '../../api/business';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RESTAURANT_KEY } from '../../constants/queryCache';
import Autocomplete from '../autocomplete/autocomplete.component';
import { Benefits } from '../../constants/benefits';
import RichEditor from '../rich-editor/rich-editor.component';

export const BusinessProfileSummary = (props) => {
    const { profile, isPublic } = props;
    const [editSummary, setEditSummary] = useState(false);
    const [benefits, setBenefits] = useState(profile.benefits);
    const [summary, setSummary] = useState(profile.summary || "");

    const queryClient = useQueryClient();
    const updateProfileMutation = useMutation({
        mutationFn: updateBusinessProfile,
        onSuccess: (data) => {
            queryClient.setQueryData([RESTAURANT_KEY, data.businessSlug], data);
            setEditSummary(false);
        },
    })

    const updateBusiness = () => {
        const newProfile = {
            ...profile,
            summary,
            skills: benefits
        }
        updateProfileMutation.mutate(newProfile);
    }

    return (
        <div className='w-100 charcoal'>
            <div className='flex flex-row justify-between ml-10'>
                <h1>{"Restaurant Overview"}</h1>
                {editSummary && !isPublic && <Button color="aperol" onClick={updateBusiness}>{"Save Section"}</Button>}
                {!editSummary && !isPublic && (
                    <div className='flex flex-row align-center ml-20 cursor-pointer' onClick={() => setEditSummary(true)}>
                        <div className='mr-5 gray font-size-15'>{"Edit Section"}</div>
                        <IoPencil className='gray' />
                    </div>
                )}
            </div>
            <div className='mt-10 ml-10 gray'>
                <RichEditor content={summary} onChange={text => setSummary(text)} editable={editSummary} />
            </div>
            <div className='flex flex-row ml-10 mt-20'>
                {benefits.map((skill) => (
                    <Chip
                        button={editSummary}
                        key={skill}
                        label={skill} sx={{
                            "marginRight": "10px"
                        }}
                        onDelete={editSummary ? () => setBenefits(benefits.filter(sk => sk !== skill)) : null}
                    />
                ))}
            </div>

            {editSummary &&
                <div className='flex ml-10 mt-10'>
                    <Autocomplete
                        options={Benefits}
                        fullWidth
                        onChange={(e, value) => {
                            if (value) {
                                setBenefits(prev => [...new Set([...prev, value])])
                            }
                        }}
                        placeholder="Add Benefit"
                    />
                </div>
            }
        </div>
    )
}

export default BusinessProfileSummary;
