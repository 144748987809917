import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [errors, setError] = useState(null);

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                if (currentUser) {
                    await currentUser.reload();
                    if (currentUser.emailVerified) {
                        // Email is already verified, redirect to home page
                        navigate('/home');
                    } else {
                        // Verify the email
                        await currentUser.sendEmailVerification();
                        // Redirect to the user profile page
                        navigate('/user');
                    }
                } else {
                    // User is not logged in, redirect to home page
                    navigate('/');
                }
            } catch (error) {
                console.error('Error verifying email:', error);
                setError([error.message]);
            }
        };

        verifyEmail();
    }, [navigate, currentUser]);

    return (
        <div>
            <h1>Verifying Email...</h1>
            {errors && errors.length > 0 && errors.map((error) => <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>)}
        </div>
    );
}

export default VerifyEmail;