import React from 'react'
import { IoBusinessOutline, IoCalendar, IoLocationOutline, IoPencil } from 'react-icons/io5';
import { mapDegreeTypeToString } from '../../constants/degree';

const UserProfileDegree = (props) => {
    const { degree, setDegrees, isPublic } = props;
    const endString = degree.endDate ?
        new Date(degree.endDate).toLocaleDateString("en-us", { year: "numeric", month: "long" }) :
        "Present";

    return (
        <div className='user-profile__workexperience'>
            <div className='flex flex-row justify-between mb-5'>
                <div className='flex flex-row'>
                    <h2>{`${mapDegreeTypeToString(degree.degreeType)} in ${degree.fieldOfStudy}`}</h2>
                    {!isPublic &&
                        <>
                            <div className='flex flex-row align-center ml-20 cursor-pointer'
                                onClick={() => setDegrees(prev => {
                                    return {
                                        ...prev,
                                        [degree.degreeId]: {
                                            ...degree,
                                            editing: true
                                        }
                                    }

                                })}
                            >
                                <div className='mr-5 gray font-size-15'>{"Edit Degree"}</div>
                                <IoPencil className='gray' />
                            </div></>
                    }
                </div>
            </div>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row align-center'>
                    <div className='flex flex-row align-center'>
                        <IoBusinessOutline className='gray mr-5' />
                        <div className='data_field'>{degree.school}</div>
                    </div>
                    <div className='flex flex-row align-center ml-10'>
                        <IoLocationOutline className='gray mr-5' />
                        <div className='data_field'>{degree.location}</div>
                    </div>
                </div>
                <div className='flex flex-row align-center'>
                    <IoCalendar className='gray mr-5' />
                    <div className='data_field'>
                        {new Date(degree.startDate).toLocaleDateString("en-us", { year: "numeric", month: "long" })}
                        {" - "}
                        {endString}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfileDegree