import React from 'react'
import Input from '../input/input.component'
import { Autocomplete as MuiAutocomplete } from '@mui/material';
import { gray } from '../../constants/colors';

const Autocomplete = (props) => {
  const { placeholder, sx, ...rest } = props;
  return (
    <MuiAutocomplete
      {...rest}
      sx={{
        "& .MuiAutocomplete-root .MuiAutocomplete-input": {
          padding: "10px"
        },
        "& .MuiAutocomplete-inputRoot": {
          padding: "10px"
        },
        "& .MuiInputLabel-root": {
          fontFamily: "Montserrat",
          color: gray,
          fontSize: "15px",
          fontWeight: "400",
        }
      }}
      renderInput={(params) => <Input {...params} placeholder={placeholder} />}
    />
  )
}

export default Autocomplete