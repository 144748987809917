export const DegreeTypes = [
    { value: 'BACHELOR', label: 'Bachelor', plural: 'Bachelors' },
    { value: 'MASTER', label: 'Master', plural: 'Masters' },
    { value: 'DOCTORATE', label: 'Doctorate', plural: 'Doctorates' },
    { value: 'ASSOCIATE', label: 'Associate', plural: 'Associates' },
    { value: 'CERTIFICATE', label: 'Certificate', plural: 'Certificates' },
    { value: 'DIPLOMA', label: 'Diploma', plural: 'Diplomas' },
    { value: 'HIGH_SCHOOL', label: 'High School', plural: 'High School' },
    { value: 'OTHER', label: 'Other', plural: 'Other' },
]

export const mapDegreeTypeToString = (degreeType) => {
    return DegreeTypes.find(type => type?.value === degreeType)?.plural;
}