export const PROFILE_KEY = 'profiles';
export const RESTAURANT_KEY = 'restaurants';
export const JOBS_KEY = 'jobs';
export const JOB_POSITIONS_KEY = 'jobPositions';

export const addToPaginatedList = (old, newItem) => {
    return {
        ...old,
        items: [...old.items, newItem]
    }
}

export const updatePaginatedList = (old, updatedItem, getId) => {
    return {
        ...old,
        items: old.items.map(item => getId(updatedItem) === getId(item) ? updatedItem : item)
    }
}

export const removeFromPaginatedList = (old, deletedItem, getId) => {
    return {
        ...old,
        items: old.items.filter(item => getId(deletedItem) !== getId(item))
    }
}