import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserAvatar from '../user-avatar/user-avatar.component';
import { IoPersonCircleSharp, IoSettingsSharp, IoStarSharp, IoCallSharp, IoIdCardSharp } from "react-icons/io5";
import { logout } from '../../firebase';
import { aperol } from '../../constants/colors';
import { useQuery } from '@tanstack/react-query';
import { PROFILE_KEY } from '../../constants/queryCache';
import { createUserProfile, getUserProfile } from '../../api/user';
import { Button } from '../button.styles';
import { useModal } from '../../contexts/modal.context';
import BizProfileSwitcher from '../biz-profile-switcher/biz-profile-switcher';
import { getCurrentBusinessProfile } from '../../utils/storage';

const NavProfileDropdown = (props) => {
    const { currentUser } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { setModal } = useModal();
    const activeBusinessProfile = getCurrentBusinessProfile();

    const { data, isLoading, } = useQuery({
        queryKey: [PROFILE_KEY, currentUser.uid], queryFn: async () => {
            try {
                return await getUserProfile();
            } catch (err) {
                if (err.response.status === 404) {
                    return await createUserProfile({
                        name: currentUser.displayName,
                        email: currentUser.email,
                    });
                }
                throw err;
            }
        }
    });

    const navigate = useNavigate();

    const handleOutsideClick = (event) => {
        if (event.target.closest('.nav-profile-dropdown') === null) {
            setIsOpen(false);
        }
    };

    const openSwitcher = () => {
        setIsOpen(false);
        setModal({
            modal: "business-profile-switcher",
            children: <BizProfileSwitcher />,
            show: true
        });
    }

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    if (!currentUser || isLoading) return null;

    const displayProfile = activeBusinessProfile === "user" ? data : data?.businesses.find(biz => biz.businessSlug === activeBusinessProfile);

    return (
        <div className="nav-profile-dropdown">
            <UserAvatar profile={displayProfile} onClick={() => setIsOpen(true)} />
            {isOpen && (
                <ul className="navbar__options-dropdown">
                    <li
                        className='mb-5'
                        onClick={() => {
                            navigate(getCurrentBusinessProfile() === "user" ? "/user" : "/restaurant");
                            setIsOpen(false);
                        }}>
                        <div className='navprofile-profile'>
                            <UserAvatar profile={displayProfile} />
                            <div className='flex-column w-80'>
                                <div className='navprofile-username'>{displayProfile.name}</div>
                                <div className='navprofile-email'>{displayProfile.email ?? displayProfile.location}</div>
                            </div>
                        </div>
                    </li>
                    <li
                        className='flex align-center'
                        onClick={() => {
                            navigate(displayProfile.businessSlug ? `/restaurant/${displayProfile.businessSlug}` : `/user/${displayProfile.userSlug}`);
                            setIsOpen(false);
                        }}>
                        <IoPersonCircleSharp className='mr-10 navprofile-icon' color={aperol} />
                        Live Profile
                    </li>
                    {displayProfile.businessSlug &&
                        <li
                            className='flex align-center'
                            onClick={() => {
                                navigate(`/positions`);
                                setIsOpen(false);
                            }}>
                            <IoIdCardSharp className='mr-10 navprofile-icon' color={aperol} />
                            Positions Center
                        </li>
                    }
                    <li
                        className='flex align-center'
                        onClick={() => {
                            navigate("/user/" + currentUser._id + "?deepLink=reviews")
                        }}>
                        <IoSettingsSharp className='mr-10 navprofile-icon' color={aperol} />
                        Settings
                    </li>
                    <li
                        className='flex align-center'
                        onClick={() => {
                            navigate("/user/" + currentUser._id + "?deepLink=reviews")
                        }}>
                        <IoStarSharp className='mr-10 navprofile-icon' color={aperol} />
                        My Reviews
                    </li>
                    <li
                        className='flex align-center mb-10'
                        onClick={() => {
                            navigate("/user/" + currentUser._id + "?deepLink=reviews")
                        }}>
                        <IoCallSharp className='mr-10 navprofile-icon' color={aperol} />
                        Contact Us
                    </li>
                    <li className='flex flex-column align-center justify-center'>
                        <Button
                            color={"gray"}
                            style={{ width: "100%", marginBottom: "10px" }}
                            onClick={() => openSwitcher()}
                        >
                            Switch Account
                        </Button>
                        <Button
                            style={{ width: "100%" }}
                            onClick={() => logout()}
                        >
                            Logout
                        </Button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default NavProfileDropdown;