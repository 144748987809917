import React from 'react';
import MatMenuItem from '@mui/material/MenuItem';
import { gray } from '../../constants/colors';

const MenuItem = (props) => {
    return (
        <MatMenuItem {...props} sx={{
            fontFamily: "Montserrat",
            color: gray,
            fontSize: "15px",
            fontWeight: "400",
        }}>{props.children}</MatMenuItem>
    );
}

export default MenuItem;