import React from 'react';

import "../../styles/navbar.scss";
import "../../styles/user-profile.scss";

const UserAvatar = ({ profile, onClick, onProfile }) => {
    const avatarContent = profile.name ? getInitials(profile.name, onProfile) : null;

    const style = onProfile ? "user-profile__avatar" : "user-profile__navbar__avatar";
    const textStyle = onProfile ? "user-profile__avatar__text" : "user-profile__navbar__avatar__text";

    return (
        <div className={style} onClick={onClick}>
            {profile.assets?.profile?.url ? (
                <img src={profile.assets?.profile?.url} alt="User Avatar" />
            ) : (
                <div className={textStyle}>{avatarContent}</div>
            )}
        </div>
    );
};

const getInitials = (profile, bothInitials) => {
    const names = profile.split(" ");
    const firstInitial = names[0].charAt(0);
    return bothInitials ? firstInitial + names[names.length - 1].charAt(0) : firstInitial;
};

export default UserAvatar;