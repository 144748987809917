import axios from "axios";
import { BaseUrl, isDev } from "./stage";

class WebClient {
  constructor() {
    this.url = BaseUrl;
  }

  setTokenFunction(tokenFunc) {
    this.accessTokenFunc = tokenFunc;
  }

  async getHeaders() {
    if (isDev) {
      return {
        userId: await this.accessTokenFunc()
      }
    }

    return {
      AuthorizationToken: await this.accessTokenFunc(),
    };
  }

  async get(path, opts = {}) {
    const response = await axios.get(this.url + path, {
      params: opts?.params,
      headers: await this.getHeaders(),
    });
    return response.data;
  }

  async post(path, data, opts = {}) {
    const response = await axios.post(this.url + path, data, {
      params: opts?.params,
      headers: await this.getHeaders(),
    });
    return response.data;
  }

  async put(path, data, opts = {}) {
    const response = await axios.put(this.url + path, data, {
      params: opts?.params,
      headers: await this.getHeaders(),
    });
    return response.data;
  }

  async patch(path, data, opts = {}) {
    const response = await axios.patch(this.url + path, data, {
      params: opts?.params,
      headers: await this.getHeaders(),
    });
    return response.data;
  }

  async delete(path, opts = {}) {
    const response = await axios.delete(this.url + path, {
      params: opts?.params,
      headers: await this.getHeaders(),
    });
    return response.data;
  }
}

const webClient = new WebClient();
export default webClient;
