export const mapEmploymentTypeToString = (hours) => {
    return EmploymentTypes.find(type => type?.value === hours)?.label;
}

export const EmploymentTypes = [
    {value: "FULL_TIME", label: "Full Time"},
    {value: "PART_TIME", label: "Part Time"},
    {value: "CONTRACT", label: "Contract"},
    {value: "TEMPORARY", label: "Temporary"},
    {value: "INTERNSHIP", label: "Internship"},
    {value: "OTHER", label: "Other"}
]