export async function signup(userInfo) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/signup`, {
        method: 'post',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(userInfo),
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}

export async function login(userInfo) {
    const response = await fetch(`/api/user/login`, {
        method: 'post',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(userInfo),
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}

export async function getUserById(userId) {
    const response = await fetch(`/api/user/`+userId, {
        method: 'get',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}

export async function updateUserById(userId, userInfo) {
    const response = await fetch(`/api/user/` + userId, {
        method: 'put',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(userInfo),
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}

export async function getLoggedInUser() {
    const response = await fetch(`/api/user/loggedInUser`, {
        method: 'get',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}

export async function logout() {
    const response = await fetch(`/api/user/logout`, {
        method: 'put',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        credentials: 'include'
    })
    const result = await response.json()
    return result.success;
}

export async function forgotPassword(email) {
    const response = await fetch(`/api/user/forgot-password`, {
        method: 'post',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({
            email
        }),
        credentials: 'include'
    })
    const result = await response.json()
    return result.success;
}

export async function resetPassword(passwordInfo) {
    const response = await fetch(`/api/user/reset-password`, {
        method: 'post',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(passwordInfo),
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}
export async function activateUser(userId) {
    const response = await fetch(`/api/user/reactivate-user?userId=${userId}`, {
        method: 'put',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}

export async function deactivateUser(userId) {
    const response = await fetch(`/api/user/deactivate-user?userId=${userId}`, {
        method: 'put',
        mode: 'cors',
        headers: {
            "Content-Type": 'application/json'
        },
        credentials: 'include'
    })
    const result = await response.json()
    return result;
}
