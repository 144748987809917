import React from 'react'
import BorderlessInput from '../input/borderless-input.component'
import { IoLocation, IoSearch } from 'react-icons/io5'

const JobSearchBar = (props) => {
    const { keyword, setKeyword, location, setLocation, onSubmit } = props;
    return (
        <form className={"job-search-bar-container " + props.className} onSubmit={onSubmit}>
            <div className={"w-40"}>
                <BorderlessInput
                    fullWidth
                    placeholder='Job Search'
                    startAdornment={<IoSearch />}
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                />
            </div>
            <div className={"w-60 flex flex-row align-center"}>
                <div className='vertical-divider' style={{ marginRight: 0 }} />
                <BorderlessInput
                    fullWidth
                    placeholder='Location'
                    startAdornment={<IoLocation />}
                    endAdornment={
                        props.endAdornment
                    }
                    value={location} onChange={e => setLocation(e.target.value)}
                />
            </div>
        </form>
    )
}

export default JobSearchBar