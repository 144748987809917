import React from 'react'
import { TextField } from '@mui/material';
import { gray } from '../../constants/colors';

const TextArea = (props) => {
    const { error, ...other } = props;
    return (
        <>
            <TextField
                multiline
                {...other}
                sx={{
                    "textarea": {
                        fontFamily: "Montserrat",
                        color: gray,
                        fontSize: "15px",
                        fontWeight: "400",
                        lineHeight: 1.5,
                    },
                    "& .MuiInputBase-root": {
                        borderRadius: "8px",
                        paddingLeft: "20px",
                        paddingRight: "20px"
                    }
                }}
            />
            {error && <div className='text-error'>{error.message}</div>}
        </>
    )
}

export default TextArea