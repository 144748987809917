import React from 'react'
import { Select as MuiSelect } from '@mui/material';
import { gray } from '../../constants/colors';

const Select = (props) => {
    const { sx, ...other } = props;
    return (
        <MuiSelect
            {...other}
            sx={{
                fontFamily: "Montserrat",
                color: gray,
                fontSize: "15px",
                fontWeight: "400",
                "& .MuiOutlinedInput-input": {
                    padding: "10px 10px",
                },
                ...sx
            }}
        >
            {props.children}
        </MuiSelect>
    )
}

export default Select