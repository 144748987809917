import React from 'react'

const UserProfileReferences = (props) => {
    const { profile, isPublic } = props;
    const { references } = profile;
    return (
        <div>
            <h1>{`${references?.length || ""} References on Bettershift`}</h1>
            <div className='gray'>{"Ask a coworker to write one!"}</div>
        </div>
    )
}

export default UserProfileReferences