import React, { useState } from 'react'
import Input from '../input/input.component';
import TextArea from '../textarea/textarea.component';
import { Button } from '../button.styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import UploadFile from '../upload-file/upload-file.component';
import { RESTAURANT_KEY } from '../../constants/queryCache';
import { useForm } from "react-hook-form"
import CircularProgress from '../circular-progress/circular-progress';
import { updateBusinessProfile } from '../../api/business';


const BusinessProfileHeaderForm = (props) => {
    const { profile } = props;
    const [photoURL, setPhotoURL] = useState(profile.assets?.profile?.url);
    const [headerURL, setHeaderURL] = useState(profile.assets?.background?.url);

    const queryClient = useQueryClient();
    const updateProfileMutation = useMutation({
        mutationFn: updateBusinessProfile,
        onSuccess: (data) => {
            queryClient.setQueryData([RESTAURANT_KEY, data.businessSlug], data)
            props.setModal({ modal: "edit-biz-profile", show: false });
        },
    })

    const updateProfile = (values) => {
        const { name, email, headline, location, website } = values;

        const newProfile = {
            businessId: profile.businessId,
            name,
            email,
            headline,
            location,
            website
        }
        updateProfileMutation.mutate(newProfile);
    }

    const onProfileImageUploaded = async (url) => {
        setPhotoURL(url);
    }

    const onHeaderImageUploaded = async (url) => {
        setHeaderURL(url);
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: profile.name,
            email: profile.email,
            headline: profile.headline || "",
            location: profile.location || "",
            website: profile.website || ""
        }
    });

    return (
        <div className='user-profile__header__form'>
            <h1>{"Edit Header"}</h1>
            <div className='flex flex-row mt-20'>
                <div className='mr-20'>
                    <h2>{"Profile Image"}</h2>
                    <div className='user-profile__header__form_profile_image' style={{
                        backgroundImage: photoURL ? `url(${photoURL})` : 'url("images/default_profile.png")'
                    }}>
                        <UploadFile
                            assetKey="profile"
                            profileType={"business"}
                            businessId={profile.businessId}
                            onFileUploaded={onProfileImageUploaded}
                            accept="image/*"
                            renderOverlay={({ uploading }) => (
                                uploading ?
                                    <div className='user-profile__header__form_profile_text'>
                                        <CircularProgress color={"white"} />
                                    </div>
                                    :
                                    <label className='user-profile__header__form_profile_text cursor-pointer' htmlFor='profile'>
                                        {"Upload a New Image"}
                                    </label>
                            )}
                        />
                    </div>
                </div>
                <div className='mr-20'>
                    <h2>{"Header Image"}</h2>
                    <div className='user-profile__header__form_header_image' style={{
                        backgroundImage: headerURL ? `url(${headerURL}` : 'url("images/default_profile_background.jpeg")'
                    }}>
                        <UploadFile
                            assetKey="background"
                            profileType={"business"}
                            businessId={profile.businessId}
                            onFileUploaded={onHeaderImageUploaded}
                            accept="image/*"
                            renderOverlay={({ uploading }) => (
                                uploading ?
                                    <div className='user-profile__header__form_profile_text'>
                                        <CircularProgress color={"white"} />
                                    </div>
                                    :
                                    <label className='user-profile__header__form_profile_text cursor-pointer' htmlFor='background'>
                                        {"Upload a New Image"}
                                    </label>
                            )}
                        />
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit(updateProfile)} style={{ padding: 0 }}>
                <h2 className='mt-20 mb-10'>{"Name"}</h2>
                <Input fullWidth error={errors.name} inputProps={{
                    ...register("name", {
                        required: "Name is required", maxLength: { value: 80, message: "Max length of 80 characters" },
                    })
                }} />
                <h2 className='mt-20 mb-10'>{"Description"}</h2>
                <TextArea fullWidth minRows={2} inputProps={{
                    ...register("headline", {
                        maxLength: { value: 1000, message: "Max length of 1000 characters" },
                    })
                }} />
                <h2 className='mt-20 mb-10'>{"Email Address"}</h2>
                <Input fullWidth type="email" inputProps={{
                    ...register("email", {
                        maxLength: { value: 200, message: "Max length of 200 characters" },
                    }), type: "email"
                }} />
                <h2 className='mt-20 mb-10'>{"Location"}</h2>
                <Input fullWidth inputProps={{
                    ...register("location", {
                        required: false,
                        maxLength: { value: 200, message: "Max length of 200 characters" },
                    })
                }} />
                <h2 className='mt-20 mb-10'>{"Website"}</h2>
                <Input fullWidth type={"url"} inputProps={{
                    ...register("website", {
                        required: false,
                        maxLength: { value: 200, message: "Max length of 200 characters" },
                    })
                }} />

                <div className='flex flex-row align-center justify-end mt-20'>
                    <div className='cursor-pointer gray mr-20' onClick={() => props.setModal({ modal: "edit-profile", show: false })}>{"Cancel"}</div>
                    <Button color="aperol" type="submit">{"Save Header"}</Button>
                </div>
            </form >
        </div>
    )
}

export default BusinessProfileHeaderForm