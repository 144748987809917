import React from 'react'
import UserProfileDegreeForm from './user-profile-degree-form.component';
import UserProfileDegree from './user-profile-degree.component';
import Chip from '../chip/chip.component';

const UserProfileDegreeList = (props) => {
    const { profile, isPublic } = props;
    const [degrees, setDegrees] = React.useState(profile.degrees || {});
    return (
        <>
            <div className='flex flex-row justify-between ml-10 mb-10'>
                <h1>{"Education"}</h1>
                {!isPublic &&
                    <Chip label="+ Add Another Degree" button onClick={() => setDegrees(prev => {
                        const newDegree = {
                            degreeId: crypto.randomUUID(),
                            editing: true
                        }
                        return { ...prev, [newDegree.degreeId]: newDegree }
                    })} />
                }
            </div>
            <div>
                {[...Object.values(degrees)]
                    .sort((x, y) => new Date(y.endDate) - new Date(x.startDate))
                    .map((degree, index) => degree.editing ? (
                        <UserProfileDegreeForm profile={profile} degrees={degrees} key={index} degree={degree} setDegrees={setDegrees} />
                    ) : <UserProfileDegree key={index} profile={profile} degrees={degrees} degree={degree} setDegrees={setDegrees} isPublic={isPublic} />
                    )}
            </div>
        </>
    )
}

export default UserProfileDegreeList