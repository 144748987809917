import React from 'react';
import { useLocation } from 'react-router-dom';
import ResetPassword from '../reset-password/reset-password';
import VerifyEmail from '../verify-email/verify-email';


const AuthAction = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh'
        }}>
            {mode === 'resetPassword' ? <ResetPassword oobCode={oobCode} /> : <VerifyEmail />}
        </div>
    );
};

export default AuthAction;