import { Avatar, Divider, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react'
import cx from 'classnames';
import Chip from '../chip/chip.component';
import { IoBookmark, IoBookmarkOutline, IoEllipse } from 'react-icons/io5';
import { Button } from '../button.styles';

const maxDescriptionLength = 120;

const JobSearchTile = (props) => {
    const { jobPosting, divider, isFavorite, favoriteJob, highlighted } = props;
    const description = jobPosting.description;

    const firstThreeBenefits = jobPosting.benefits.slice(0, 3);

    return (
        <>
            <ListItem alignItems="flex-start" className={cx({ 'cursor-pointer': true, 'bg-secondary': highlighted })} onClick={props.onClick}>
                <ListItemAvatar>
                    <Avatar alt={jobPosting.business.name} src={jobPosting.business?.assets?.profile?.url} variant={"rounded"} />
                </ListItemAvatar>
                <div className='flex flex-column w-100'>
                    <div className='flex flex-row justify-between w-100'>
                        <ListItemText
                            primary={jobPosting.title}
                            secondary={
                                <>
                                    {description.length > maxDescriptionLength ? description.substring(0, maxDescriptionLength) + "..." : description}
                                </>
                            }
                        />
                        <div className='mt-10'>
                            <Button color={isFavorite ? "charcoal" : "white"} icon="true" onClick={() => favoriteJob(!isFavorite)}>
                                {isFavorite ? <IoBookmark size={20} /> : <IoBookmarkOutline size={20} className={'gray'} />}
                            </Button>
                        </div>
                    </div>

                    <div className="flex flex-row align-center mt-10">
                        {firstThreeBenefits.map((benefit) => (
                            <Chip key={benefit} variant={"filled"} label={benefit} sx={{ marginRight: "5px" }} />
                        ))}
                    </div>
                    <div className='job-search-tile-biz-row'>
                        <div className='job-search-tile-biz-item'>{jobPosting.business.name}</div>
                        <IoEllipse style={{ width: "10%" }} size={8} />
                        <div className='job-search-tile-biz-item'>{jobPosting.business.location}</div>
                        <IoEllipse style={{ width: "10%" }} size={8} />
                        <div className='job-search-tile-biz-item'><b>{`Applicants: `}</b>{jobPosting.applicants || 0}</div>
                    </div>
                </div>
            </ListItem>
            {divider && <Divider variant="fullWidth" component="li" />}
        </>
    )
}

export default JobSearchTile