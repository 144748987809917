import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat'
        ].join(','),
    },
});

export default function GlobalCssOverride(props) {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>

    );
}