import { useState } from "react"
import {
    Form,
    FormButton,
    FormH1,
    FormInput,
} from './forgot-password.styles.jsx';
import { forgotPassword } from "../../firebase";

function ForgotPassword() {

    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const forgotPasswordHandler = (e) => {
        setErrors([]);
        setSuccess(false);
        setLoading(true);
        e.preventDefault();
        forgotPassword(email)
            .then(res => setSuccess(true))
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
    }

    return <div className="forgot-password w-100"
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh'
        }}>
        <Form onSubmit={forgotPasswordHandler}>
            <FormH1>Forgot Password</FormH1>
            {success && <p style={{ color: "green", marginBottom: "10px" }}>Email sent! Check your inbox</p>}
            {errors && errors.length > 0 && errors.map((error) => <p style={{ color: "red", marginBottom: "10px" }}>{error}</p>)}
            <FormInput type='email' placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <FormButton disabled={loading || success} type='submit'>Submit</FormButton>
        </Form>
    </div>
}

export default ForgotPassword