export const GetCurrentStage = () => {
    if (window.location.href.includes('localhost')) {
        return 'dev'
    } else if (window.location.href.includes('alpha.bettershift.com')) {
        return 'alpha'
    }

    return 'prod';
}

const apiUrls = {
    dev: 'http://localhost:8080/api',
    alpha: 'https://alpha-api.bettershift.com/api',
    prod: 'https://api.bettershift.com/api',
}

export const isDev = GetCurrentStage() === 'dev';
export const isAlpha = GetCurrentStage() === 'alpha';
export const isProd = GetCurrentStage() === 'prod';

export const BaseUrl = apiUrls[GetCurrentStage()];
export const CurrentStage = GetCurrentStage();