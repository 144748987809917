import webClient from "./webClient";


export const getUserProfile = async(userSlug) => {
    return webClient.get(userSlug ? `/users/profile?userSlug=${userSlug}` : `/users/profile`);
}

export const createUserProfile = async(body) => {
    return webClient.post(`/users/profile`, body);
}

export const updateUserProfile = async(profile) => {
    return webClient.put(`/users/profile`, profile);
}

export const getPresignedUrl = async({key, filename, filetype }) => {
    const resp = await webClient.post(
        `/users/presignedurl`,
        {
            key,
            filename,
            filetype
        },
    );
    return resp;
}