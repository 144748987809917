const bgColor = '#fff';
const primaryColor = '#01bf71';
const phoneWidth = '768px';
const tabletWidth = '1280px';
const indigo = '#54686E';
const blackberry = '#353E42';
const aperol = '#A2552D';
const secondaryButton = '#D8D8DC';
const gray = '#757575';
const charcoal = '#232422';
const honey = '#CB923D';
const goji = "#B53B2A"
const danger = 'rgba(181, 20, 20, 0.6)';

export {
    bgColor,
    primaryColor,
    phoneWidth,
    tabletWidth,
    indigo,
    blackberry,
    aperol,
    secondaryButton,
    gray,
    charcoal,
    honey,
    goji,
    danger
};