import React, { useState } from 'react'

const Wizard = (props) => {
    const [data, setData] = useState(props.initialData)
    const [page, setPage] = useState(0);

    return (
        <>
            {props.steps[page]({
                data,
                setData,
                prevPage: () => setPage(page - 1),
                nextPage: () => setPage(page + 1),
            })}
        </>
    )
}

export default Wizard