import jobsearchLanding from "../../images/jobsearch_landing.png";
import findTalentLanding from "../../images/findtalent_landing.png";

export const getHeroData = profile => {
    if (profile === "user") return {
        "title": "Unlock Your Potential",
        "description": "Welcome to the BetterShift community of restaurant professionals. Your newest position is just a click away - explore, apply and embark on a path to success!",
        image: jobsearchLanding
    }

    return {
        title: "Explore High-Quality Applicants",
        description: "No matter the position you are looking to fill, our platform connects you with a range of workers in your area. You newest employee is just a click away!",
        image: findTalentLanding
    }
} 