import "../../styles/rich-editor.scss";

import {
    MenuButtonBold,
    MenuButtonBulletedList,
    MenuButtonItalic,
    MenuButtonOrderedList,
    MenuButtonRedo,
    MenuButtonUndo,
    MenuControlsContainer,
    MenuDivider,
    RichTextEditor,
} from "mui-tiptap";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import { EditorProvider } from "@tiptap/react";


export const RichEditor = (props) => {
    let transformedContent = null;
    try {
        transformedContent = props.content && JSON.parse(props.content);
    } catch (error) {
        transformedContent = props.content;
    }

    return props.editable ? (
        <RichTextEditor
            editable={props.editable}
            extensions={[StarterKit]}
            content={transformedContent}
            onUpdate={({ editor }) => props.onChange(JSON.stringify(editor.getJSON()))}
            renderControls={() => props.editable && (
                <MenuControlsContainer>
                    <MenuButtonUndo />
                    <MenuButtonRedo />
                    <MenuDivider />
                    <MenuButtonBold />
                    <MenuButtonItalic />
                    <MenuButtonBulletedList />
                    <MenuButtonOrderedList />
                    {/* Add more controls of your choosing here */}
                </MenuControlsContainer>
            )}
        />
    ) : (
        <EditorProvider
            editable={false}
            extensions={[StarterKit]}
            content={transformedContent}
        />
    );

};

export default RichEditor;
