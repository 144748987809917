import React from 'react'
import CircularProgress from '../circular-progress/circular-progress';
import BusinessProfilePosition from './biz-profile-position.component';
import { useNavigate } from 'react-router-dom';
import { IoArrowForward } from 'react-icons/io5';
import { aperol } from '../../constants/colors';

const BusinessProfilePositionList = (props) => {
    const { jobPostings, isPublic, isLoading, isError } = props;
    const navigate = useNavigate();

    return (
        <div className='m-1 mb-20'>
            <div className="flex flex-row align-center justify-between">
                <h1 className='mb-20'>{"Open Positions"}</h1>
                {!isPublic &&
                    <div className='aperol flex flex-row align-center cursor-pointer' onClick={() => navigate("/positions")}>
                        {"Edit in Positions Center"}
                        <IoArrowForward color={aperol} className='ml-10' />
                    </div>}
            </div>
            {isLoading && <div className='loading-container'><CircularProgress /></div>}
            {isError && <div className='mt-10'>Error fetching job postings</div>}
            {jobPostings && jobPostings.length === 0 && <div className='mt-10'>No open positions</div>}
            <div className='flex flex-column'>
                {jobPostings && jobPostings.map((jobPosting, index) => (
                    <BusinessProfilePosition
                        key={jobPosting.jobPostingId}
                        jobPosting={jobPosting}
                        isPublic={isPublic}
                        divider={index !== jobPostings.length - 1}
                    />
                ))}
            </div>
        </div>
    )
}

export default BusinessProfilePositionList