import React, { useState } from 'react';
import { getPresignedUrl as getUserPresignedUrl } from '../../api/user';
import { getPresignedUrl as getBusinessPresignedUrl } from '../../api/business';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const twentyMB = 20 * 1000 * 1000;

const UploadFile = (props) => {
    const { assetKey, onFileUploaded, maxFileSize = twentyMB, profileType = "user", businessId } = props;
    const [file, setFile] = useState(null);
    const [fileUploading, setFileUploading] = useState(false);
    const [error, setError] = useState(null);

    const mutation = useMutation({
        mutationFn: profileType === "user" ? getUserPresignedUrl : getBusinessPresignedUrl,
        onSuccess: (data) => {
            onSubmit(data.url, data.fields);
        },
        onError: (error) => {
            setError(error.message);
        }
    });

    const onSelectFile = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        if (file.size > maxFileSize) {
            setError(`File size is too large, must be less than ${maxFileSize / 1000} MB`);
            return;
        }

        setFile(file);
        setError(null);
        mutation.mutate({ businessId, key: assetKey, filename: file.name, filetype: file.type });
    }

    const onSubmit = async (url, fields) => {
        setFileUploading(true);
        const formData = new FormData();
        for (let key in fields) {
            formData.append(key, fields[key]);
        }
        formData.append('file', file);
        try {
            await axios.post(url, formData);
            onFileUploaded(url + fields.key);
        } catch (err) {
            setError(err.message);
        } finally {
            setFileUploading(false);
        }
    }

    return (
        <>
            {props.renderOverlay({ uploading: fileUploading || mutation.isLoading })}
            <input type="file" name="file" accept={props.accept} onChange={onSelectFile} id={assetKey} style={{ display: 'none' }} />
            {error && <div className='text-error '>{error}</div>}
        </>
    )
}
export default UploadFile;