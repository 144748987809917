import React from 'react'
import MuiCircularProgress from '@mui/material/CircularProgress';
import { aperol } from '../../constants/colors';

const CircularProgress = (props) => {
    return (
        <MuiCircularProgress
            size={props.size || 40}
            sx={{
                color: props.color || aperol,
            }} />
    )
}

export default CircularProgress