import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';

import Home from './pages/home';
import About from './pages/about'
import ContactPage from './pages/contact';
import SiteGuidelines from './pages/site-guidelines';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';
import CopyrightPolicy from './pages/copyright-policy';
import NavBar from './components/nav-bar/nav-bar.component';
import Footer from './components/footer/footer.component';
import UserProfile from './pages/user-profile';
import { ModalProvider } from "./contexts/modal.context";
import { AuthProvider } from "./contexts/auth.context";
import Logout from './pages/logout';
import ForgotPassword from './components/forgot-password/forgot-password.component';
import Sidebar from './components/side-bar/side-bar.component';
import PrivateRoute from './components/private-route/private-route.component';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'react-tooltip/dist/react-tooltip.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthAction from './components/auth-action/auth-action';
import GlobalCssOverride from './components/global-css-override';
import Jobs from './pages/jobs';
import Positions from './pages/positions';
import RestaurantProfile from './pages/restaurant-profile';

const queryClient = new QueryClient()


function App() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <GlobalCssOverride>
          <AuthProvider>
            <ModalProvider>
              <Sidebar isOpen={isOpen} toggle={toggle} />
              <NavBar toggle={toggle} />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/user' element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                <Route path='/user/:userSlug' element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                <Route path='/restaurant' element={<PrivateRoute><RestaurantProfile /></PrivateRoute>} />
                <Route path='/restaurant/:businessSlug' element={<RestaurantProfile />} />
                <Route
                  path='/positions'
                  element={
                    <PrivateRoute requiresBusinessProfile>
                      <Positions />
                    </PrivateRoute>
                  }
                />
                <Route path='/jobs' element={<Jobs />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<ContactPage />} />
                <Route path='/site-guidelines' element={<SiteGuidelines />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/copyright-policy' element={<CopyrightPolicy />} />
                <Route path='/terms-of-service' element={<TermsOfService />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/auth' element={<AuthAction />} />
              </Routes>
            </ModalProvider>
          </AuthProvider>
          <Footer />
        </GlobalCssOverride>
      </QueryClientProvider>
    </Router>
  );
};

export default App;