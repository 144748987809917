import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import React from 'react'
import { gray } from '../../constants/colors';

const Checkbox = (props) => {
    const { label, ...other } = props;
    return (
        <FormControlLabel
            control={
                <MuiCheckbox
                    {...other}
                    sx={{
                        color: gray,
                        '&.Mui-checked': {
                            color: gray,
                        },
                    }}
                />
            }
            label={label}
            sx={{
                '& .MuiFormControlLabel-label': {
                    fontFamily: "Montserrat",
                    color: gray,
                    fontSize: "15px",
                    fontWeight: "400",
                }
            }}
        />
    )
}

export default Checkbox