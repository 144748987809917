import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { gray } from '../../constants/colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontWeight: 600
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: gray
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Table = (props) => {
    const { rows, columns } = props;
    return (
        <TableContainer>
            <MuiTable sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <StyledTableCell key={column.field} align={column.align}>{column.headerName}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.id}>
                            {columns.map((column) => (
                                <StyledTableCell key={column.field} align={column.align} variant={column.variant}>
                                    {column.render ? column.render(row) : row[column.field]}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    ))}
                    {rows.length === 0 && props.renderEmpty ? props.renderEmpty() : null}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
}

export default Table;