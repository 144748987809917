import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import googleLogo from '../../images/google-logo.svg'
import { login, signInGoogle } from '../../firebase';
import { Link, useNavigate } from 'react-router-dom';
import {
  Form,
  FormButton,
  FormH1,
  FormInput,
  Background,
  ModalWrapper,
  ModalContent,
  CloseModalButton
} from './signin.styles'

import "./signin.scss";

export const ModalSignIn = ({ showModal, setShowModal, setShowModalSignUp }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [errors, setErrors] = useState([])
  const modalRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
  });

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  const signIn = (e) => {
    e.preventDefault()
    setErrors([])
    login({ email, password })
      .then((response) => {
        setShowModal(false);
        navigate("/user");
      })
      .catch((error) => {
        setErrors([error.message]);
      });
  }

  useEffect(
    () => {
      document.addEventListener('keydown', keyPress);
      return () => document.removeEventListener('keydown', keyPress);
    },
    [keyPress]
  );

  return (
    <>
      {showModal ? (
        <Background onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <div data-aos="fade-zoom-in" data-aos-duration="500">
              <ModalWrapper showModal={showModal}>
                <ModalContent>
                  <Form onSubmit={signIn}>
                    <div className="form-header-grouping">
                      <FormH1>Welcome to BetterShift!</FormH1>
                      <span className="form-subtitle">Have a voice, tell your story.</span>
                    </div>
                    {<button type="button" className='google__sign-in-button' onClick={(e) => {
                      e.preventDefault();
                      signInGoogle().then(() => {
                        setShowModal(false);
                        navigate("/user");
                      }).catch((error) => {
                        setErrors([error.message]);
                      });
                    }}>
                      <img src={googleLogo} alt="google" />
                      Sign in with Google
                    </button>}
                    <div className="google__sign-in__divider">
                      <span className="google__sign-in__divider__text">Or</span>
                      <div className="google__sign-in__divider__line"></div>
                    </div>
                    {errors && errors.length > 0 && errors.map((error) => <p style={{ color: "red" }}>{error}</p>)}
                    <FormInput placeholder="Email Address" type='email' value={email} onChange={(e) => setEmail(e.target.value)} required ariaLabel="Email" />
                    <FormInput placeholder="Password" type='password' value={password} onChange={(e) => setPassword(e.target.value)} required ariaLabel="Password" />
                    <FormButton type='submit'>Sign In</FormButton>
                    <div className="forgot-password-wrapper">
                      <Link to="/forgot-password" onClick={() => { setShowModal(false); }}>
                        Forgot Password?
                      </Link>
                    </div>


                    <span className="signup-action">Don't have an account? &nbsp;<div style={{ cursor: "pointer", fontWeight: "500", color: "black" }}
                      onClick={() => {
                        setShowModal(false);
                        setShowModalSignUp(true)
                      }}>Sign Up</div></span>

                  </Form>


                </ModalContent>
                <CloseModalButton
                  aria-label='Close modal'
                  color='rgb(200,200,200)'
                  onClick={() => setShowModal(prev => !prev)}
                />
              </ModalWrapper>
            </div>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};